<template>
  <div class="tabList">
    <slot></slot>
  </div>
</template>

<script setup>
import {toRefs, useAttrs} from "vue";
import {getDomHeight} from "@utils/tool/getDomHeight";

const props = defineProps({
  censusHeight: {
    type: String,
    default: '88px',
  },
  searchClass: {
    type: String,
    default: "queryBoxOne",
  },
  marginTop: {
    type: String,
    default: "20px",
  },
})
const {censusHeight, searchClass, marginTop} = toRefs(props);
const {domHeight} = getDomHeight('.' + searchClass.value);
const attrs = useAttrs();
</script>

<style lang="scss" scoped>
.tabList {
  margin-top: v-bind(marginTop);
  height: calc(100% - 40px - v-bind(censusHeight) - v-bind(domHeight));
}
</style>