import {ref , onMounted } from 'vue';
import {stockTypeList} from "@/utils/api/product/product";
import {stockTypeIn,stockTypeOut} from '@/utils/api/procurement/stock.js'
import message from "@utils/tool/message";
export default function useOtherOrderType(options = {}){
    const orderTypeOptions = ref([])
    function  setOrderTypeOptions(list){
        list.forEach(item=>{
            if(item.list){
                item.list = item.list.map(i=>{
                    return {
                        orderType:i.typeId,
                        orderTypeDesc:i.typeName,
                    }
                })
            }
        })
        list.unshift({
            orderType:'',
            orderTypeDesc:'全部类型',
        })
        orderTypeOptions.value = list
    }
    async function getStockTypeInList() {
        const api = options?.type === 'all' ? stockTypeList : options?.type === 'in' ? stockTypeIn : stockTypeOut
        let res = await api({})
        if (res.code !== 0) {
            message.error(res.msg);
            return;
        }
        let list = res.data?.list || []
        setOrderTypeOptions(list)
    }

    onMounted(async ()=>{
        if(options?.autoGet){
            await getStockTypeInList()
        }
    })

    return{
        orderTypeOptions,
        getStockTypeInList
    }
}