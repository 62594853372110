<template>
  <el-cascader v-bind="$attrs" :options="orderTypeOptions" :props="oneSelectCascaderOrderTypeProp" :placeholder="placeholder" clearable v-model="modelValue" />
</template>

<script setup>
import {computed} from 'vue';
import placerholder from '@/utils/config/placerholder'
import { useVModel} from '@vueuse/core'
import { oneSelectCascaderOrderTypeProp } from '@/utils/config/formConfig'
import useOrderType from "@utils/hooks/order/useOrderType";

const props = defineProps({
  modelValue: {
    type: Array,
    default: ()=>([])
  },
  type:{
    type:String,
    default:'all'
  }
})
const { orderTypeOptions } = useOrderType({autoGet: true,type:props.type})
const emit = defineEmits(['update:modelValue', 'onChange'])
const modelValue = useVModel(props, 'modelValue', emit)
const placeholder = computed(() => {
  return props.type === 'all' ? placerholder.orderTypeSelect : props.type === 'in' ? placerholder.inStockType : placerholder.outStockType
})

</script>
<script>
import {defineComponent} from "vue";
export default defineComponent({
  name: "OrderTypeCascader",
});
</script>

<style lang="scss" scoped>

</style>
